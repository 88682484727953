import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo'
import './NavbarStyles.css'

const Navbar = () => {
    
    const [isMobile, setIsMobile] = useState(false);

    return (
        <nav className="navbar">
            <Logo />

            <ul 
              className={isMobile ? "nav-links-mobile" : "nav-links"}
              onClick={() => setIsMobile(false)}
            >
                <Link to="/" className="home">
                    <li>Home</li>
                </Link>
                <Link to="/support" className="support">
                    <li>Support</li>
                </Link>
                <Link to="/privacy" className="privacy">
                    <li>Privacy Policy</li>
                </Link>
                <Link to="/termsOfService" className="termsOfService">
                    <li>Terms of Service</li>
                </Link>
            </ul>
            <button 
              className="mobile-menu-icon"
              onClick={() => setIsMobile(!isMobile)}
            >
                {isMobile ? <i className="fas fa-times"></i> :  <i className="fas fa-bars"></i>}
            </button>
        </nav>
    )
}


export default Navbar;