import React from 'react';

const Logo = () => {
    return (
        <img src={require("./assets/HeadacreLogo.png")} height={75} alt="HeadacreLogo" />
        );
};

export default Logo;

