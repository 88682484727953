import React from 'react';

const TermsOfServicePage = () => {
  return (
    <div className="page-container">
      <h1 className="page-title">Terms of Service for Headacre iOS App</h1>
      <section className="page-body-text">
        <h2>Effective Date: June 19th, 2023</h2>
        <p>These Terms of Service ("Terms") govern your use of the Headacre iOS app (the "App") provided by Headacre ("we," "our," or "us"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the App.</p>
      </section>
      <section className="page-body-text">
        <h2>1. Use of the App</h2>
        <p>1.1 Eligibility: You must be at least 13 years old to use the App. By using the App, you represent and warrant that you meet the age requirement.</p>
        <p>1.2 License: Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to use the App for your personal, non-commercial use.</p>
        <p>1.3 User Account: Some features of the App may require you to create an account. You are responsible for maintaining the confidentiality of your account information, including your login credentials. You agree to provide accurate and complete information when creating an account, and to promptly update any information that may change.</p>
      </section>
      <section className="page-body-text">
        <h2>2. User Content</h2>
        <p>2.1 User Responsibilities: You are solely responsible for any content you upload, submit, or otherwise make available through the App ("User Content"). You represent and warrant that you own or have the necessary rights, licenses, and permissions to use and share your User Content.</p>
        <p>2.2 License to User Content: By submitting User Content, you grant us a worldwide, royalty-free, non-exclusive, perpetual, irrevocable, and fully sublicensable license to use, reproduce, modify, adapt, publish, translate, distribute, publicly perform and display, and create derivative works of your User Content in connection with the operation and promotion of the App.</p>
        <p>2.3 Prohibited Content: You agree not to submit User Content that is unlawful, defamatory, infringing, obscene, abusive, offensive, or otherwise objectionable or violates any third-party rights. We reserve the right to remove or disable access to any User Content that violates these Terms.</p>
      </section>
      <section className="page-body-text">
        <h2>3. Intellectual Property</h2>
        <p>3.1 Ownership: The App, including its content, features, and functionality, is owned by Headacre and its licensors and is protected by copyright, trademark, and other intellectual property laws. You acknowledge and agree that the App contains proprietary information and materials that are owned by us or our licensors.</p>
        <p>3.2 Restrictions: You may not copy, modify, distribute, sell, lease, reverse engineer, decompile, or disassemble the App or any part thereof unless expressly permitted by applicable law.</p>
      </section>
      <section className="page-body-text">
        <h2>4. Third-Party Services</h2>
        <p>4.1 The App may integrate with third-party services, including but not limited to Firebase and RevenueCat. Your use of such third-party services is subject to their respective terms and policies. We are not responsible for the availability, functionality, or security of third-party services.</p>
      </section>
      <section className="page-body-text">
        <h2>5. Disclaimer of Warranty</h2>
        <p>5.1 The App is provided on an "as is" and "as available" basis. We do not warrant that the App will be error-free, uninterrupted, or free of viruses or other harmful components. To the fullest extent permitted by applicable law, we disclaim all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
      </section>
      <section className="page-body-text">
        <h2>6. Limitation Liability</h2>
        <p>6.1 To the fullest extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the App, including but not limited to loss of profits, data, or other intangible losses.</p>
      </section>
      <section className="page-body-text">
        <h2>7. Governing Law and Dispute Resolution</h2>
        <p>7.1 These Terms shall be governed by and construed in accordance with the laws of US Government. Any dispute arising out of or in connection with these Terms shall be resolved exclusively through binding arbitration in accordance with the rules of the US Government. The seat of arbitration shall be Ohio, and the language of the arbitration shall be English.</p>
      </section>
      <section className="page-body-text">
        <h2>8. Changes to the Terms</h2>
        <p>8.1 We reserve the right to modify or update these Terms at any time. We will notify you of any material changes by posting the revised Terms within the App or through other reasonable means. Your continued use of the App after the effective date of the revised Terms constitutes your acceptance of the updated Terms.</p>
      </section>
      <section className="page-body-text">
        <h2>9. Contact Us</h2>
        <p>9.1 If you have any questions, concerns, or inquiries regarding these Terms, please contact us at support@headacreapp.com.</p>
      </section>
      <section className="page-body-text">
        <h3>Please review these Terms carefully. By using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms.</h3>
      </section>
      <section className='page-body-text'>
        <p>...</p>
      </section>

    </div>
  );
};

export default TermsOfServicePage;