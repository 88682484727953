import React from 'react';
import './pageStyles.css'

const SupportPage = () => {
  return (
    <div className="page-container">
      <h1 className="page-title">Support & FAQ</h1>
      <section className="page-body-text">
        <h2>Q1</h2>
        <p>This is the placeholder of the answer to question 1.</p>
      </section>
      <section className="page-body-text">
        <h2>Q2</h2>
        <p>This is the placeholder of the answer to question 2.</p>
      </section>
      <section className="page-body-text">
        <h2>Q3</h2>
        <p>This is the placeholder of the answer to question 3.</p>
      </section>
      <section className="page-body-text">
        <h2>Q4</h2>
        <p>This is the placeholder of the answer to question 4.</p>
      </section>
      <section className="page-body-text">
        <h2>Further Support Coming Soon!</h2>
        <p>Submit your questions at xxx@xxx.com</p>
      </section>
      {/* Rest of the sections */}
    </div>
  );
};

export default SupportPage;
