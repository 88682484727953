import React from 'react';
import { AppStoreButton } from "react-mobile-app-button";
import './pageStyles.css'

const HomePage = () => {
  const iOSUrl = "https://www.youtube.com";
  
  return (
    <div className="page-container">
      <h1 className="page-title">A Digital Tool to Help Manage Your Business</h1>
      <section className="page-body-text">
        <h3>Download Headacre from the App Store Today!</h3>
      </section>
      <section>
        <AppStoreButton
        url={iOSUrl}
        theme={"light"}
        className="apple-button"
        
        />
      </section>
    </div>
  );
};

export default HomePage;
