import React from 'react';
import './pageStyles.css'

const PrivacyPolicyPage = () => {
  return (
    <div className="page-container">
      <h1 className="page-title">Privacy Policy</h1>
      <section className="page-body-text">
        <h2>Introduction</h2>
        <p>Provide a brief introduction to your privacy policy and explain its purpose.</p>
      </section>
      <section className="page-body-text">
        <h2>1. Information We Collect</h2>
        <p>1.1 Personal Information: When you create an account or interact with certain features of the App, we may collect the following personal information:</p>
        <p>-Name: We collect your name to personalize your experience within the App and to provide a more customized user experience.</p>
        <p>1.2 Non-Personal Information: We may also collect non-personal information automatically, such as device information (e.g., device model, operating system version), mobile application usage data, and crash reports. This information helps us improve the functionality and performance of the App.</p>
      </section>
      <section className="page-body-text">
        <h2>2. How We Use Collected Information</h2>
        <p>2.1 We use the personal information, including your name, to personalize your experience within the App and to provide a more customized user experience.</p>
        <p>2.2 We use non-personal information collected from the App to analyze usage patterns, monitor app performance, and make improvements to enhance user experience.</p>
      </section>
      <section className="page-body-text">
        <h2>3. Third-Party Services</h2>
        <p>3.1 Firebase: We use Firebase, a service provided by Google LLC, to collect and analyze both personal and non-personal information about the App's usage. Firebase may collect and process data in accordance with its privacy policy. To learn more about how Firebase handles information, please review Google's privacy policy.</p>
        <p>3.2 RevenueCat: We use RevenueCat to handle in-app purchases and subscriptions within the App. RevenueCat may collect and process data related to purchases, subscriptions, and user interactions within the App. To learn more about how RevenueCat handles information, please review RevenueCat's privacy policy.</p>
      </section>
      <section className="page-body-text">
        <h2>4. Data Retention</h2>
        <p>4.1 We retain personal information, including your name, collected from the App for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
        <p>4.2 We retain non-personal information collected from the App for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
      </section>
      <section className="page-body-text">
        <h2>5. Data Security</h2>
        <p>5.1 We take reasonable measures to protect the information collected from the App against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
      </section>
      <section className="page-body-text">
        <h2>6. Childrens Privacy</h2>
        <p>6.1 The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected personal information from a child under 13, please contact us using the contact information provided below, and we will promptly delete such information.</p>
      </section>
      <section className="page-body-text">
        <h2>7. Changes to this Privacy Policy</h2>
        <p>7.1 We reserve the right to modify or update this Privacy Policy at any time. We will notify you of any material changes by updating the Effective Date at the beginning of this Privacy Policy. We encourage you to review this Privacy Policy periodically for any changes.</p>
      </section>
      <section className="page-body-text">
        <h2>8. Contact Us</h2>
        <p>8.1 If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at support@headacreapp.com.</p>
      </section>
      <section className="page-body-text">
        <h3>Last Modified: June 19th, 2023</h3>
        <p>Please note that this Privacy Policy only applies to the App and not to any third-party websites, services, or applications linked to or integrated with the App. We encourage you to review the privacy policies of those third parties before using their services.</p>
      </section>
      <section className='page-body-text'>
        <p>...</p>
      </section>
    </div>
  );
}

export default PrivacyPolicyPage;

