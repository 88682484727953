import React from 'react';
import Home from './pages/HomePage';
import Support from './pages/SupportPage';
import PrivacyPolicy from './pages/PrivacyPolicyPage';
import TermsOfService from './pages/TermsOfServicePage';
import Footer from './components/Footer';
import { HashRouter, Route, Switch } from 'react-router-dom';

import Navbar from './components/Navbar';


const App = () => {
  return (
    <div>
      <HashRouter>
        <Navbar />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/support" component={Support} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/termsOfService" component={TermsOfService} />
        </Switch>
      </HashRouter>

      <Footer />
    </div>
  );
};

export default App;

